import { type ComponentPropsWithoutRef, forwardRef, type ReactNode, useId } from "react";
import { RiCheckLine } from "react-icons/ri";
import { useField } from "remix-validated-form";

import { FieldError, FieldInfo } from "~/components/ui/forms/field-messages";
import { Label } from "~/components/ui/forms/label";
import { cn } from "~/utils/classnames";

interface CheckboxProps extends ComponentPropsWithoutRef<"input"> {
  label?: ReactNode;
  labelPosition?: "left" | "right";
  infoMessage?: ReactNode;
  errorMessage?: ReactNode;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(
  { id, label, labelPosition = "right", infoMessage, errorMessage, className, ...props },
  ref
) {
  const genId = useId();

  return (
    <div className={cn("group", className)} aria-disabled={props.disabled}>
      <label
        htmlFor={id || genId}
        className={cn(
          "flex cursor-pointer items-center gap-3 disabled:cursor-not-allowed disabled:opacity-60",
          labelPosition === "left" ? "flex-row-reverse justify-end" : "flex-row"
        )}
      >
        <div className="relative flex items-center justify-center">
          <input
            id={id || genId}
            type="checkbox"
            aria-invalid={!!errorMessage}
            aria-describedby={`${id || genId}-info ${id || genId}-error`}
            className="peer size-5 cursor-pointer appearance-none rounded border border-grey-300 bg-white transition-colors checked:bg-grey-900 aria-invalid:border-danger-500 aria-invalid:ring-danger-500 aria-invalid:checked:bg-danger-500"
            ref={ref}
            {...props}
          />
          <RiCheckLine className="pointer-events-none absolute size-3.5 scale-0 text-white opacity-0 transition-all peer-checked:scale-100 peer-checked:opacity-100" />
        </div>
        <Label htmlFor={id || genId} className="pointer-events-none px-0">
          {label}
        </Label>
      </label>
      {infoMessage ? (
        <FieldInfo
          inputId={id || genId}
          className={cn(labelPosition === "left" ? "px-0" : "pl-8 pr-0")}
        >
          {infoMessage}
        </FieldInfo>
      ) : null}
      {errorMessage ? (
        <FieldError
          inputId={id || genId}
          className={cn(labelPosition === "left" ? "px-0" : "pl-8 pr-0")}
        >
          {errorMessage}
        </FieldError>
      ) : null}
    </div>
  );
});

export const ValidatedCheckbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function ValidatedCheckbox(props: CheckboxProps, ref) {
    const { error, getInputProps } = useField(props.name ?? "");

    return <Checkbox ref={ref} {...getInputProps({ errorMessage: error, ...props })} {...props} />;
  }
);
